/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCompany, getCompany } from "../../../../reducers/companySlice";
import companyService from "../../../../utils/services/company";
import { useDispatch } from "react-redux";
import {
  selectLoadingUI,
  loadingOn,
  loadingOff,
} from "../../../../reducers/uiSlice";
import { Notification } from "../../../../containers/notification";
import { useSnackbar } from "notistack";
import { selectUser } from "../../../../reducers/userSlice";
import {
  selectChangesDeposit,
  setChanges,
} from "../../../../reducers/depositSlice";
import { createChangeRecord } from "../../../../reducers/depositChangesSlice";
import dateUtils from "../../../../utils/date";
import { useNavigate } from "react-router-dom";

// CSS

import styles from "./styles.module.css";

//MATERIAL
import InputComponent from "../../../inputs";
import Toolbar from "../../../card/toolbar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import logo from "../../../../assets/img/facebook-pixel.jpg";
import { Checkbox } from "@material-ui/core";

export default function FacebookComponent() {
  const [pixelId, setPixelId] = useState("");
  const [facebookDomainVerification, setFacebookDomainVerification] =
    useState("");
  const [metaAccessToken, setMetaAccessToken] = useState("");
  const [metaActive, setMetaActive] = useState(false);
  const [
    metaRemoveClientIdAfterConverting,
    setMetaRemoveClientIdAfterConverting,
  ] = useState(false);
  const [metaUseWithoutClid, setMetaUseWithoutClientId] = useState(false);
  const [updated, setUpdated] = useState(false);
  const company = useSelector(selectCompany);
  const user = useSelector(selectUser);
  const loading = useSelector(selectLoadingUI);
  const changes = useSelector(selectChangesDeposit);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    if (company.FacebookPixelId && company.FacebookPixelId !== "") initialize();
  }, [company]);

  const initialize = () => {
    setPixelId(company?.FacebookPixelId ?? "");
    setFacebookDomainVerification(company?.FacebookDomainVerification ?? "");
    setMetaAccessToken(company?.metaConfig?.accessToken ?? "");
    setMetaActive(company?.metaConfig?.active ?? false);
    setMetaRemoveClientIdAfterConverting(
      company?.metaConfig?.removeClientIdAfterConverting ?? false
    );
    setMetaUseWithoutClientId(company?.metaConfig?.useWithoutClid ?? false);
  };

  const updateChanges = (fieldName, newValue) => {
    const newChanges = [];
    newChanges.push(...changes);
    var index = -1;
    const change = {
      text: "",
      oldValue: null,
      newValue: null,
      date: dateUtils.getUTC().slice(0, 19).replace("T", " "),
    };

    change.newValue = newValue;
    change.oldValue = company && company[fieldName] ? company[fieldName] : "";

    switch (fieldName) {
      case "FacebookPixelId":
        change.text = "Cambios en el Pixel ID de la tienda";
        break;
      case "FacebookDomainVerification":
        change.text = "Cambios en el código de verificación de dominio";
        break;
      case "metaAccessToken":
        change.text = "Cambios en el token de acceso";
        break;
      case "metaActive":
        change.text = "Activación de la meta api conversion";
        break;
      case "metaRemoveClientIdAfterConverting":
        change.text = "Remover Client ID después de convertir";
        break;
      case "metaUseWithoutClid":
        change.text = "Usar sin Client ID";
        break;
      default:
        break;
    }

    index = newChanges.findIndex(
      (item) => item.text.toString() === change.text.toString()
    );

    if (index !== -1) {
      change.oldValue = newChanges[index].oldValue;
      newChanges[index] = change;
    } else {
      newChanges.push(change);
    }
    dispatch(setChanges(newChanges));
  };

  const handleCancel = () => initialize();

  const handleSave = () => {
    dispatch(loadingOn());
    companyService
      .updateCompany(
        company._id,
        {
          _id: company._id,
          FacebookPixelId: pixelId,
          FacebookDomainVerification: facebookDomainVerification,
          metaConfig: {
            accessToken: metaAccessToken,
            active: metaActive,
            removeClientIdAfterConverting: metaRemoveClientIdAfterConverting,
            useWithoutClid: metaUseWithoutClid,
          },
        },
        user.token
      )
      .then((res) => {
        dispatch(loadingOff());
        getCompany(company._id, dispatch);
        enqueueSnackbar(
          Notification({
            text: "Datos de la tienda actualizados",
            variant: "success",
            withDetails: false,
          })
        );
        setUpdated(false);
        createChangeRecord(
          {
            user: user,
            username: user.username,
            fullName: `${user.firstName} ${user.lastName}`,
            idUser: user.id,
            idCompany: company._id,
            idObject: company._id,
            module: "settings",
            submodule: "facebook",
            createdDate: dateUtils.getUTC().slice(0, 19).replace("T", " "),
            changes: changes,
          },
          user.token,
          dispatch
        ).catch((err) => console.log(err));
      })
      .catch((err) => {
        enqueueSnackbar(
          Notification({
            text: "Error al actualizar datos de la tienda",
            variant: "error",
            withDetails: false,
          })
        );
        dispatch(loadingOff());
      });
  };

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <div className={styles.parentContainer}>
      <button className={styles.backButton} onClick={() => handleClose()}>
        <span>
          <ArrowBackIcon />
        </span>
        Regresar
      </button>
      <div className={styles.infoContainer}>
        <div className={styles.titleLogoContainer}>
          <Toolbar title="Facebook Pixel" />
          <img src={logo} alt="logo" className={styles.imageElement} />
        </div>
        <div className={styles.infoDataContainer}>
          <p className={styles.textDescription}>
            El píxel de Facebook es un código que sirve como herramienta de
            análisis para optimizar tus anuncios de Facebook Ads, construir
            audiencia segmentadas y volver a dirigir el público que ya ha
            realizado alguna acción en tu tienda.
          </p>
          <div className={styles.inputStackContainer}>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>Pixel ID</label>

              <InputComponent
                placeholder="Ej. 12345678901234"
                type="text"
                handler={(e) => {
                  setPixelId(e.target.value);
                  updateChanges("FacebookPixelId", e.target.value);
                  setUpdated(true);
                }}
                disabled={loading}
                validation={pixelId === ""}
                value={pixelId}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>
                Verificación de dominio
              </label>

              <InputComponent
                placeholder="Ej. 12345678901234"
                type="text"
                handler={(e) => {
                  setFacebookDomainVerification(e.target.value);
                  updateChanges("FacebookDomainVerification", e.target.value);
                  setUpdated(true);
                }}
                disabled={loading}
                validation={facebookDomainVerification === ""}
                value={facebookDomainVerification}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>Meta API Conversion</label>
              <Checkbox
                checked={metaActive}
                onChange={(e) => {
                  setMetaActive(e.target.checked);
                  updateChanges("metaActive", e.target.value);
                  setUpdated(true);
                }}
                color="primary"
                inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                style={{ margin: "0px", padding: "0px" }}
              />
            </div>
            {metaActive ? (
              <div className={styles.inputContainer}>
                <label className={styles.labelInput}>Access Token</label>

                <InputComponent
                  placeholder=""
                  type="text"
                  handler={(e) => {
                    setMetaAccessToken(e.target.value);
                    updateChanges("metaAccessToken", e.target.value);
                    setUpdated(true);
                  }}
                  disabled={loading}
                  validation={metaAccessToken === ""}
                  value={metaAccessToken}
                />
              </div>
            ) : null}
            {metaActive ? (
              <div className={styles.inputContainer}>
                <label className={styles.labelInput}>
                  Remover click id luego de la conversión
                </label>

                <Checkbox
                  checked={metaRemoveClientIdAfterConverting}
                  onChange={(e) => {
                    setMetaRemoveClientIdAfterConverting(e.target.checked);
                    updateChanges(
                      "metaRemoveClientIdAfterConverting",
                      e.target.value
                    );
                    setUpdated(true);
                  }}
                  color="primary"
                  inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                  style={{ margin: "0px", padding: "0px" }}
                />
              </div>
            ) : null}

            {metaActive ? (
              <div className={styles.inputContainer}>
                <label className={styles.labelInput}>Usar sin click id</label>

                <Checkbox
                  checked={metaUseWithoutClid}
                  onChange={(e) => {
                    setMetaUseWithoutClientId(e.target.checked);
                    updateChanges("metaUseWithoutClid", e.target.value);
                    setUpdated(true);
                  }}
                  color="primary"
                  inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                  style={{ margin: "0px", padding: "0px" }}
                />
              </div>
            ) : null}
            <div className={styles.buttonsContainer}>
              <button
                disabled={!updated}
                onClick={() => {
                  setUpdated(true);
                  handleCancel();
                }}
                className={styles.cancelButton}
              >
                Cancelar
              </button>
              <button
                disabled={!updated}
                className={styles.confirmButton}
                onClick={() => {
                  setUpdated(true);
                  handleSave();
                }}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
